/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/whatwg-fetch@3.6.20/dist/fetch.umd.min.js
 * - /npm/numeral@2.0.6/min/numeral.min.js
 * - /npm/numeral@2.0.6/min/locales.min.js
 * - /npm/moment@2.30.1/min/moment.min.js
 * - /npm/moment@2.30.1/min/locales.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
